import React, { useState } from 'react'
import { init, send } from 'emailjs-com'
import { Form, Input, TextArea, Button } from 'semantic-ui-react'

import './Denuncies.css'
import { Card, Row, Col, Alert, Badge } from 'react-bootstrap'
import * as IconName from "react-icons/fi";


const Denuncies = () => {
    const [name, setName] = useState('');
    const [dni, setDni] = useState('');
    const [telefon, setTelefon] = useState('');
    const [email, setEmail] = useState('');
    const [persona, setPersona] = useState('');    
    const [message, setMessage] = useState('');

    const options = [
        { key: "1", value: "1", text: "Persona afectada" },
        { key: "2", value: "2", text: "Testimoni" }
      ];


    const isValidEmail = email => {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(String(email).toLowerCase());
    };

    const handleOnSubmit = (e) => {
        e.preventDefault();
        const SERVICE_ID = process.env.REACT_APP_SERVICE_ID_D;
        const TEMPLATE_ID = process.env.REACT_APP_COMPLAINTS_TEMPLATE_ID;
        const USER_ID = process.env.REACT_APP_USER_ID_D;
        const Swal = require('sweetalert2')
        
        var tipus_persona = "Persona afectada";
        if (persona == 2) tipus_persona = "Testimoni";

        const templateParams = {
            message: message,
            email: email,
            name: name,
            dni: dni,
            telefon: telefon,
            persona: tipus_persona

        };

        if (!(isValidEmail(email))){
            Swal.fire({
                icon: 'info',
                title: 'Correu incorrecte',
                toast: true,
                position: 'top',
                timer: 3000,
                showConfirmButton: false
              }) 
        } else {
            send(SERVICE_ID, TEMPLATE_ID, templateParams, USER_ID)
            .then((result) => {
              Swal.fire({
                  icon: 'success',
                  text: 'ens posarem en contacte el més aviat possible',
                  title: 'Missatge enviat correctament',
                  toast: true,
                  position: 'top',
                  timer: 3000,
                  showConfirmButton: false
                }) 
              setEmail('');
              setMessage('');;
            }, (error) => {
              console.log(error.text);
              Swal.fire({
                icon: 'error',
                title: 'Ui, alguna cosa no ha funcionat',
                text: error.text,
                toast: true,
                position: 'top',
                timer: 3000,
                showConfirmButton: false
              })
            }); 
          }
        e.target.reset()
      };

    init('user_Yk3LBKTPzdA96IdP6TLg8');

    return (
        <div className='Contact'>
    <Row>
    <Col>
        <br />
        <Alert variant='danger'>
        <IconName.FiMail className="me-2"  />Bústia de denúncies
        </Alert>

        <Card>
            <Card.Header>Denúncies anònimes</Card.Header>
            <Card.Body>
                <Card.Subtitle className="mb-2">Espai anònim</Card.Subtitle>
                <Card.Text>Obrim un espai segur per a denúncies anònimes sobre actes irrespectuosos que hàgiu patit o presenciat: abusos de qualsevol mena, actes d'odi per la vostra procedència, aparença, orientació sexual o identitat de gènere.
                    <br/>Des del Vic Vòlei Callís, en diàleg amb altres professionals, et volem donar suport.
                    <br/><b>Què fem amb les denúncies que ens arriben?</b>

                    <br/>1. mantenim l'anonimat, també entre els membres de la Junta (<b>només llegeixen la bústia presdiència</b>)
                    <br/>2. no farem res sense parlar amb tu
                    <br/>3. comptem amb assessorament extern si és necessari
                    <br/>Gràcies per confiar en nosaltres.</Card.Text>
            </Card.Body>
        </Card>
    </Col>
    <Col>
        <br />
        <Alert variant='danger'>
            T'escoltem! <br />  Escriu-nos i us respondrem el més aviat possible <Badge bg="secondary">Contacte amb nosaltres</Badge>
            <br/>
            <a href="/docs/PROTOCOL ASSATJAMENT VIC VOLEI CALLIS.pdf" target="_blank">PROTOCOL ASSATJAMENT VIC VOLEI CALLIS</a>
        </Alert>

            <Form onSubmit={handleOnSubmit}>
                <h4>DENÚNCIA INTERNA</h4>
                <p>COMUNICACIÓ DE SUPÒSIT DE SITUACIÓ DE VIOLÈNCIA O ABÚS, SITUACIÓ D’ASSETJAMENT SEXUAL O PER RAÓ DE SEXE</p>
                Jo,                    
                <br/>                   
                <Form.Field
                id="form-input-control-name"
                control={Input}
                label="Nom i Cognoms"
                name="user_name"
                placeholder="El teu nom"
                required
                icon="user"
                iconPosition="left"
                value={name} onChange={e => setName(e.target.value)} />
                <br/>   
                <Form.Field
                id="form-input-control-dni"
                control={Input}
                label="DNI/PASSAPORT"
                name="user_dni"
                placeholder="Número DNI o Passport"
                required
                icon="passport"
                iconPosition="left"
                value={dni} onChange={e => setDni(e.target.value)} />
                <br/>                 
                <Form.Field
                id="form-input-control-telefon"
                control={Input}
                label="Telèfon"
                name="user_telefon"
                placeholder="Número de telèfon"
                required
                icon="phone"
                iconPosition="left"
                value={telefon} onChange={e => setTelefon(e.target.value)} />                
                <br/>   
                <Form.Field
                id="form-input-control-email"
                control={Input}
                label="Email de contacte"
                name="user_email"
                placeholder="Correu electrònic de contacte"
                required
                icon="mail"
                iconPosition="left"
                value={email} onChange={e => setEmail(e.target.value)} />
                <br/>   
                <Form.Field>
                <label for="selperson">En qualitat de: </label>
                <select name="selperson" id="selperson" onChange={(e) => setPersona(e.target.value)}>
                    <option value="1">Persona afectada</option>
                    <option value="2">Testimoni</option>
                </select>
                </Form.Field>
                <br/>   
                <Form.Field
                id='form-textarea-control-opinion'
                control={TextArea}
                label='Exposo els següents fets,'
                name='user_message'
                placeholder='Missatge explicatiu'
                required
                value={message} onChange={e => setMessage(e.target.value)} />
                <br/>   
                <b>Sol·licito</b>
                <p>Que s’activi el protocol de de discriminació, assetjament, abús o violència en el marc
                de l’activitat o entitat esportiva</p>

                <Button type='submit' color='green'>Enviar</Button>
            </Form>
    </Col>
    </Row>
</div>
    );
};

export default Denuncies;